import React, { useState, useEffect } from "react";
import "../Bookatable/Bookatable.css";
import img from "../../assets/Images/bookatable/bookatable.png";
import img2 from "../../assets/Images/bookatable/bookatableimg.png";
import img3 from "../../assets/Images/bookatable/midbooktable.png";
import img4 from "../../assets/Images/bookatable/img4.png";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Flex } from "antd";
import Download from "../Download/Download";
import Location from "../Location/Location";
import FaqComponent from "../Faq/FaqComponent";
import Faq from "../Faq/Faq";

const Bookatable = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <style>
       
      </style>
     
        <div className="bookatableContainer">
          <h1 className="bookatabletext">Book A table</h1>
          <div className="pizzabookatable pizzabookatable1-mobile">
            <div className="timeDiv">
              <div style={{gap:'0'}} className="timeSlotGroup">
                <div className="booktime">Time</div>
                <div className="booktimewhite">slot</div>
              </div>

              <div className="timeSlotGroup">
                <div className="booktimegreen">12:00</div>
                <div className="booktimegreen">13:15</div>
                <div className="booktimegreen">14:30</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">17:00</div>
                <div className="booktimegreen">18:15</div>
                <div className="booktimegreen">19:30</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">20:45</div>
                <div className="booktimegreen">22:00</div>
                
              </div>
       
              <div className="textdisply">
                <p>
                  "Book your table with us! Time slots start every 75 minutes
                  stay on track and join us for every session!"
                </p>
              </div>
            </div>

            <div 
                className="pizzaImage2" 
                style={{ backgroundImage: `url(${img})` }}>
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "60px",
                  fontFamily: "Spectral SC",
                }}
              >
                MONDAY
              </p>
              <p
                style={{
                  fontFamily: "Lavishly Yours",
                  marginTop: "-20px",
                  textAlign: "center",
                  color: "white",
                  fontSize: "40px",
                }}
              >
                To
              </p>
              <p
                style={{
                  marginTop: "-0px",
                  textAlign: "center",
                  color: "white",
                  fontSize: "60px",
                  fontFamily: "Spectral SC",
                }}
              >
                THURSDAY
              </p>
            </div>
          </div>







          <div className="pizzabookatable pizzabookatable2-mobile">
          <div 
                className="pizzaImage2" 
                style={{ backgroundImage: `url(${img3})` }}>
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "60px",
                  fontFamily: "Spectral SC",
                }}
              >
                FRIDAY
              </p>
              <p
                style={{
                  fontFamily: "Lavishly Yours",
                  marginTop: "-20px",
                  textAlign: "center",
                  color: "white",
                  fontSize: "40px",
                }}
              >
                To
              </p>
              <p
                style={{
                  marginTop: "-10px",
                  textAlign: "center",
                  color: "white",
                  fontSize: "60px",
                  fontFamily: "Spectral SC",
                }}
              >
                SUNDAY
              </p>
            </div>
            <div className="timeDiv">
              <div style={{gap:'0'}} className="timeSlotGroup">
                <div className="booktime">Time</div>
                <div className="booktimewhite">slot</div>
              </div>

              <div className="timeSlotGroup">
                <div className="booktimegreen">12:00</div>
                <div className="booktimegreen">13:15</div>
                <div className="booktimegreen">14:30</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">15:45</div>
                <div className="booktimegreen">17:00</div>
                <div className="booktimegreen">18:15</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">19:30</div>
                <div className="booktimegreen">20:45</div>
                <div className="booktimegreen">22:00</div>
              </div>
            </div>
          </div>
          {/* <div className="pizzabookatable">
            <div className="timeDiv">
              <div className="timeSlotGroup">
                <div className="booktime">Time</div>
                <div className="booktimewhite">slot</div>
              </div>

              <div className="timeSlotGroup">
                <div className="booktimegreen">08.15</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>09.30</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>10.45</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">12.00</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>13.15</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>14.30</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">15.45</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>17.00</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>18.15</div>
              </div>
              <div className="timeSlotGroup">
                <div className="booktimegreen">19.30</div>
                <div className="booktimegreen" style={{ marginLeft: "15px" }}>20.45</div>
              </div>
            </div>

            <div className="pizzaImage2">
              <img src={img3} alt="Pizza" />
              <div className="overlayTextsatur">FRIDAY</div>
              <div className="overlayTextToo" style={{ fontFamily: 'Lavishly Yours' }}>To</div>
              <div className="overlayTextSunday">SUNDAY</div>
            </div>
          </div> */}
        </div>
  
      <Download />
      <Location />
      <Faq />
    </>
  );
};

export default Bookatable;


import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteWrapper = ({ element, setLoading }) => {
  const location = useLocation();

  useEffect(() => {
    setLoading(true); 

    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 

    return () => clearTimeout(timer); 
  }, [location, setLoading]);

  return element;
};

export default RouteWrapper;

import React, { useState } from 'react';
import PizzaDaValterData from '../Pages/New Manu/Manu Data/PizzaDaValterData';
import DrinksData from '../Pages/New Manu/Manu Data/DrinksData';
import DessertsData from '../Pages/New Manu/Manu Data/DessertsData';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const results = [];

      // Helper function to search through each data source
      const searchData = (data, sourceName) => {
        Object.keys(data).forEach((category) => {
          data[category].forEach((item) => {
            if (item.title.toLowerCase().includes(term)) {
              // Add source and category info explicitly
              results.push({ ...item, category, source: sourceName });
            }
          });
        });
      };

      // Search in each data file with specific source names
      searchData(PizzaDaValterData, 'PizzaDaValter');
      searchData(DrinksData, 'Drinks');
      searchData(DessertsData, 'Desserts');

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div style={styles.container}>
      <input
        type="text"
        placeholder="Search for a menu item..."
        value={searchTerm}
        onChange={handleSearch}
        style={styles.searchInput}
      />

      {/* Display search results if there are any */}
      {searchResults.length > 0 && (
        <div style={styles.resultsContainer}>
          <h3>Search Results:</h3>
          {searchResults.map((result, index) => (
            <div key={result.id || index} style={styles.resultItem}>
              <strong>{result.title}</strong> in <em>{result.category.replace('_', ' ')}</em> from <em>{result.source}</em>
            </div>
          ))}
        </div>
      )}

      {/* Display the full menu if no search term */}
      {searchResults.length === 0 && (
        <div>
          {Object.keys(PizzaDaValterData).map((category) => (
            <div key={category} style={styles.categoryContainer}>
              <h2 style={styles.categoryTitle}>{category.replace('_', ' ')}</h2>
              <div style={styles.itemGrid}>
                {PizzaDaValterData[category].map((item) => (
                  <div key={item.id} style={styles.card}>
                    <img src={item.image} alt={item.title} style={styles.image} />
                    <h3 style={styles.title}>{item.title}</h3>
                    <p style={styles.description}>{item.description}</p>
                    <p style={styles.price}>{item.price}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '90%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
  },
  searchInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '1em',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  resultsContainer: {
    marginBottom: '2em',
  },
  resultItem: {
    fontSize: '18px',
    margin: '0.5em 0',
  },
  categoryContainer: {
    marginBottom: '2em',
  },
  categoryTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '1em',
  },
  itemGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1em',
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '1em',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  image: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0.5em 0',
  },
  description: {
    fontSize: '14px',
    color: '#666',
  },
  price: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
    marginTop: '1em',
  },
};

export default Search;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FaqComponent.css";

const faqData = [
  {
    key: "1",
    question: "Q1- How can I place an order online?",
    answer: "Download our app from the Play Store or App Store to place Pickup or Delivery orders. You can also view our food menu on our website.",
  },
  {
    key: "2",
    question: "Q2 - Do you offer vegan or gluten-free options?",
    answer: "Yes, we offer a variety of Vegan and Gluten-Free options. You can find these under the special diets section of our menu.",
  },
  {
    key: "3",
    question: "Q3 - What are your delivery hours?",
    answer: "Our delivery hours are:",
    answer1: "Monday - Thursday: 5 PM - 11 PM",
    answer2: "Friday: 12 PM - 11 PM",
    answer3: "Saturday & Sunday: 8 AM - 11 PM",
    answer4: "Please check the 'Opening Hours' section for more details.",
  },
  {
    key: "4",
    question: "Q4 - Is there a discount for pick-up orders?",
    answer: "Yes, we offer a 10% discount for pick-up orders. You can find this option in the 'Pick Up' section of our menu.",
  },
  {
    key: "5",
    question: "Q5 - Can I make a reservation online?",
    answer: "Yes, you can book a table online through our mobile app. Just click on 'Book A Table' from the Booking screen and follow the instructions.",
  },
  {
    key: "6",
    question: "Q6 - How do I know if my booking is confirmed?",
    answer: "Once your booking is confirmed, you will receive a notification in the app and can check the order screen for confirmation details.",
  },
  {
    key: "7",
    question: "Q7 - Do you have outdoor seating?",
    answer: "Yes, Pizza Da Valter offers outdoor seating with a view of Wandsworth Common. It provides a relaxing atmosphere, especially during the evening as the sun sets, giving you the feel of being on holiday.",
  },
];

const FaqComponent = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className="faq-container">
      <div className="faq-content">
        {faqData.map(({ key, question, answer }) => (
          <div key={key} className="faq-panel" style={{ maxHeight: activeKey === key ? '300px' : '200px' }}>
            <div className="faq-header" onClick={() => handleToggle(key)}>
              <h2>{question}</h2>
              <span className="faq-icon">{activeKey === key ? '-' : '+'}</span>
            </div>
            {activeKey === key && (
              <div className="faq-answer">
                {key === "3" ? (
                  <>
                    <p>{answer}</p>
                    <ul>
                      <li>Monday - Thursday: 12 : 00 - 15 : 00 && 17 : 00 - 23 : 00</li>
                      <li>Friday - Sunday: 12 : 00 - 23 : 00</li>
                    </ul>
                    <p>Please check the 'Opening Hours' section for more details.
                    </p>
                  </>
                ) : (
                  <p>{answer}{" "}
                   
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;
import pizzalogo from "../../../assets/Images/Home/PizzaLogo.png";
const DrinksData = {
    VodkaClassicCocktails: [
        { 
            id: 1, 
            title: 'Pornstar Martini', 
            description: 'Vodka, passion fruit passoa, passion fruit juice, passion fruit puree, vanilla syrup, lime juice, Prosecco shot', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Espresso Martini', 
            description: 'Vodka, Kahlua, Espresso shot, Vanilla Syrup', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 3, 
            title: 'Cosmopolitan', 
            description: 'Vodka, Triple Sec, Cranberry Juice, Sugar Syrup, lime juice', 
            price: '£11.75', 
            image:pizzalogo
        },
        { 
            id: 4, 
            title: 'Japanese Slipper', 
            description: 'Midori, Triple sec, Lime juice', 
            price: '£11.75', 
            image: pizzalogo
        }
    ],
    RumClassicCocktails: [
        { 
            id: 1, 
            title: 'Mai Tai', 
            description: 'White Rum, Triple Sec, Oregat Syrup, Dark rum, Pineapple Juice', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Mojito', 
            description: 'Rum, Fresh Mint, lime slices, sugar syrup, lemonade/soda water (choice of classic, passion fruit, strawberry, grenadine)', 
            price: '£11.75', 
            image:pizzalogo
        },
        { 
            id: 3, 
            title: 'Pina Colada', 
            description: 'Rum, Coconut Cream, Pineapple juice', 
            price: '£11.75', 
            image: pizzalogo
        }
    ],
    WhiskeyCocktails: [
        { 
            id: 1, 
            title: 'Old-Fashioned', 
            description: 'Bourbon, Sugar, water, Angostura Bitter', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Manhattan', 
            description: 'Rye Whiskey, sweet vermouth, Angostura Bitter', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 3, 
            title: 'Whiskey Sour', 
            description: 'Bourbon, Lemon Juice, Sugar Syrup, Egg white', 
            price: '£11.75', 
            image: pizzalogo
        }
    ],
    GinClassicCocktails: [
        { 
            id: 1, 
            title: 'Brumble', 
            description: 'Gin, Lemon Juice, Sugar Syrup, Grenadine', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Negroni', 
            description: 'Gin, Campari, Sweet Vermouth', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 3, 
            title: 'French 75', 
            description: 'Gin, Champagne, Lemon Juice, Sugar Syrup', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 4, 
            title: 'Clover Club', 
            description: 'Gin, Lemon Juice, Sugar Syrup, Egg White', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 5, 
            title: 'Secret Garden', 
            description: 'Gin, Apple Juice, Cucumber, Mint, Lime Juice, topped with Soda', 
            price: '£11.75', 
            image: pizzalogo
        }
    ],
    TequilaCocktails: [
        { 
            id: 1, 
            title: 'Margarita', 
            description: 'Tequila, Triple Sec, Lime Juice', 
            price: '£11.75', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Long Island Iced Tea', 
            description: 'Tequila, Rum, Vodka, Gin, Triple Sec, Lime Juice, Coke', 
            price: '£11.75', 
            image: pizzalogo
        }
    ],
    ClassicMocktails: [
        { 
            id: 1, 
            title: 'Virgin Mojito', 
            description: 'Fresh Mint, Lime Slices, Sugar Syrup, Lemonade Choice of: Classic, Strawberry, Passion Fruit, Grenadine', 
            price: '£7.50', 
            image: pizzalogo
        },
        { 
            id: 2, 
            title: 'Virgin Pornstar Martini', 
            description: 'Passion Fruit Juice, Passion Fruit puree, Vanilla Syrup, Lime Juice', 
            price: '£7.50', 
            image: pizzalogo
        }
    ],

        WhiteWine: [
            { 
                id: 1, 
                title: 'Montalto Organic Catarratto', 
                description: 'Sicilia IGT, Italy - 100% Catarratto - Fruity and dry with fresh citrus notes and a crisp, fruity palate.', 
                price: '£26.00', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Verdicchio dei Castelli di Jesi Classico, Vignamato', 
                description: 'Marches, Italy - 100% Verdicchio - Intense fruit aromas with hints of wild flowers.', 
                price: '£28.50', 
                image: pizzalogo
            },
            { 
                id: 3, 
                title: 'Chardonnay Tormaresca', 
                description: 'Puglia IGT, Italy - Bright, fruity, medium-bodied Chardonnay showing the purity and richness of Mediterranean fruit.', 
                price: '£31.00', 
                image: pizzalogo
            },
            { 
                id: 4, 
                title: 'Castel Firmian Pinot Grigio', 
                description: 'Trentino, Italy - 100% Pinot Grigio - Nicely balanced, with a gentle floral aroma, good medium weight, and a refreshing finish.', 
                price: '£34.00', 
                image: pizzalogo
            },
            { 
                id: 5, 
                title: 'Gavi di Gavi La Meirana', 
                description: 'Piedmont, Italy - 100% Cortese - Great texture and precision, fresh and dry, this is particularly characterful Gavi.', 
                price: '£46.00', 
                image: pizzalogo
            },
            { 
                id: 6, 
                title: 'Sauvignon Blanc Collio', 
                description: 'Friuli, Italy - 100% Sauvignon Blanc - Intense and fine on the nose with hints of sage, pepper, and elderberry. Bright and fresh on the palate with a long, pleasant finish.', 
                price: '£49.00', 
                image: pizzalogo
            }
        ],
        RedWine: [
            { 
                id: 1, 
                title: "Montalto Organic Nero d'Avola", 
                description: "Sicilia IGT, Italy - 100% Nero d'Avola - Notes of ripe red fruits, sweet spices, and liquorice. The fruit flavors continue on the round, generous palate.", 
                price: '£26.00', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Feudo Arancio Syrah, Sicilia', 
                description: 'Sicily, Italy - Bright ruby red wine with a warm bouquet of black pepper, spicy notes, and wild fruit.', 
                price: '£30.00', 
                image: pizzalogo
            },
            { 
                id: 3, 
                title: 'Santa Cristina Le Maestrelle', 
                description: 'Tuscany, Italy - 60% Sangiovese, 20% Merlot, 20% Syrah - Full, intense aroma of red fruit and vanilla with hints of cocoa and a long elegant finish.', 
                price: '£35.00', 
                image: pizzalogo
            },
            { 
                id: 4, 
                title: 'Bonacosta Valpolicella Classico', 
                description: 'Veneto, Italy - 70% Corvina, 25% Rondinella, 5% Molinara - Aromas of fresh cherries and raspberries accompanied by hints of spices.', 
                price: '£41.00', 
                image: pizzalogo
            },
            { 
                id: 5, 
                title: "Montepulciano d'Abruzzo", 
                description: 'Abruzzi, Italy - Peppery Montepulciano with exuberant fruit and spicy character.', 
                price: '£46.00', 
                image: pizzalogo
            },
            { 
                id: 6, 
                title: 'Peppoli Chianti Classico', 
                description: 'Tuscany, Italy - 90% Sangiovese, 10% Merlot & Syrah - Intense red fruit aromas with delicate notes of vanilla and chocolate.', 
                price: '£55.00', 
                image: pizzalogo
            },
            { 
                id: 7, 
                title: 'Il Bruciato', 
                description: 'Bolghieri DOC, Italy - 65% Cabernet Sauvignon, 20% Merlot, 15% Syrah - Fragrant fruity aromas, particularly plum, with a full-bodied and smooth finish.', 
                price: '£75.00', 
                image: pizzalogo
            },
            { 
                id: 8, 
                title: 'Barolo 2014', 
                description: 'Piedmont, Italy - 100% Nebbiolo - Complex nose with notes of rose, strawberry, and forest fruits. Delicately floral and perfumed.', 
                price: '£90.00', 
                image: pizzalogo
            }
        ],
        SparklingWineChampagne: [
            { 
                id: 1, 
                title: "Ca' del Console Prosecco Extra Dry", 
                description: 'Veneto, Italy - 100% Glera - Classic Prosecco with aromas of white peach, pear, and flowers.', 
                price: '£35.50', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Champagne Laurent-Perrier', 
                description: 'France - 50% Chardonnay, 35% Pinot Noir, 15% Pinot Meunier - Well defined, subtly rounded with expressive flavors.', 
                price: '£70.00', 
                image: pizzalogo
            }
        ],
        Rose: [
            { 
                id: 1, 
                title: 'Nina Pinot Grigio Rosé', 
                description: 'Veneto, Italy - 100% Pinot Grigio - Light-bodied, pale pink, dry rosé that is delightfully refreshing.', 
                price: '£27.00', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Calafuria Negroamaro Rosato, Tormaresca', 
                description: 'Puglia, Italy - 100% Negroamaro - Peach petal pink with intense yet delicate fragrances.', 
                price: '£39.50', 
                image: pizzalogo
            }
        ],
        Beers: [
            { 
                id: 1, 
                title: 'Peroni (Draught)', 
                price: 'Half £4.00 , Pint £6.70', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Budweiser (Bottle)', 
                price: '£5.00', 
                image: pizzalogo
            },
            { 
                id: 3, 
                title: 'Moretti (Bottle)', 
                price: '£6.70', 
                image: pizzalogo
            },
            { 
                id: 4, 
                title: 'Stella', 
                price: '£5.00', 
                image: pizzalogo
            },
            { 
                id: 5, 
                title: 'Becks (Non-Alcoholic)', 
                price: '£4.50', 
                image: pizzalogo
            }
        ],
        GinMixer: [
            { 
                id: 1, 
                title: 'Bombay Sapphire', 
                price: '£9.50 (50ml) / £7.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Sipsmith', 
                price: '£11.00 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 3, 
                title: 'Gin Mare', 
                price: '£11.00 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 4, 
                title: "Hendrick's", 
                price: '£11.00 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 5, 
                title: 'Tanqueray Number Ten', 
                price: '£11.00 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            }
        ],
        Vodka: [
            { 
                id: 1, 
                title: 'Absolut', 
                price: '£10.50 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Grey Goose', 
                price: '£11.50 (50ml) / £9.00 (25ml)', 
                image: pizzalogo
            }
        ],
        WhiskeyMixer: [
            { 
                id: 1, 
                title: 'Johnny Walker Black Label', 
                price: '£11.50 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Jack Daniels', 
                price: '£11.00 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            }
        ],
        OtherDrinks: [
            { 
                id: 1, 
                title: 'Absolut', 
                price: '£10.50 (50ml) / £8.50 (25ml)', 
                image: pizzalogo
            },
            { 
                id: 2, 
                title: 'Grey Goose', 
                price: '£10.50 (50ml) / £9.00 (25ml)', 
                image: pizzalogo
            }
        ]

    
    // Other categories (WhiteWine, RedWine, etc.) would follow the same pattern.
};

export default DrinksData;
